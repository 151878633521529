<template>
  <div class="layout-subcontainer">
    <h1 class="tit-h1">평가별 통계</h1>
    <div class="box-wrap">
      <div class="box-ct d-flex">
        <div class="form-inp sm">
          <v-select
            :items="$store.getters['userStore/GE_USER_COMPANY']"
            item-text="CD_NM"
            item-value="CD"
            outlined
            hide-details
            label="회사구분"
            placeholder="선택하세요"
            v-model="grid01Search.ASP_NEWCUST_KEY"
            @change="selectRtnLm"
          >
          <template v-slot:label>
              회사구분
            <v-icon color="#EF9191">mdi-circle-small</v-icon>
          </template>
          </v-select>
        </div>
        <div class="form-inp sm ml-2">
          <v-select
            :items="mixin_common_code_get(allCodeList,'PLT018','전체')"
            item-text="text"
            item-value="value"
            outlined
            hide-details
            label="유형타입"
            placeholder="선택하세요"
            v-model="grid01Search.LM_TY"
          ></v-select>
        </div>
        <div class="sm ml-2">
          <v-menu
            v-model="menu1"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
            content-class="calendar-modal"
            color="#FFF"
          >
            <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="dateRangeText"
                v-bind="attrs"
                v-on="on"
                readonly
                outlined
                hide-details
                class="form-inp icon-calendar"
                append-icon="svg-calendar"
                label="전체평가기간"
              ></v-text-field>
            </template>
            <div class="datepicker-range">
              <v-date-picker
                  v-model="grid01Search.START_DATE"
                  no-title
                  dark
                  color="#F7AFAE"
                  locale="ko-KR"
                  :first-day-of-week="1"
                  :day-format="mixin_getDate"
                ></v-date-picker>
              <v-date-picker
                  v-model="grid01Search.END_DATE"
                  dark
                  color="#F7AFAE"
                  no-title
                  locale="ko-KR"
                  :first-day-of-week="1"
                  :day-format="mixin_getDate"
                  :min="grid01Search.START_DATE"
                ></v-date-picker>
            </div>
            <div class="text-center pt-3 pb-3">
              <v-btn
                outlined
                class="btn-default"
                @click="menu1 = false"
              >확인</v-btn>
            </div>
          </v-menu>
        </div>

        <div class="ml-2">
          <v-text-field
            class="form-inp lg"
            name="name"
            label="시험명"
            hide-details
            outlined hint=""
            v-model="grid01Search.LM_ID_NM"
            @keyup.enter="selectRtnLm"
            persistent-hint>
          </v-text-field>
        </div>

        <div class="ml-auto align-self-center">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch')" outlined class="btn-etc2" @click="selectRtnLm" @keyup.enter="selectRtnLm" >조회</v-btn>
        </div>
      </div>
    </div>

    <!-- 상담품질평가 목록 -->
    <div class="box-wrap">
      <h2 class="tit-h2 d-flex">상담품질평가 목록</h2>
      <div class="box-ct">
        <v-data-table
          dense
          :headers="gridDataHeaders"
          :items="gridDataText"
          item-key="LM_ID"
          hide-default-footer
          class="grid-default case03"
          single-select
          calculate-widths
          fixed-header
          height="250px"
          :item-class="isActiveRow"
          @click:row="setGrid01Select"
          no-data-text="검색된 결과가 없습니다."
        ></v-data-table>
      </div>
    </div>

    <div class="box-wrap">
      <div class="box-ct d-flex">
        <div class="">
          <v-text-field
            class="form-inp lg"
            name="name"
            label="시험명"
            hide-details
            outlined hint="시험명"
            disabled
            persistent-hint
            v-model="detail.LM_ID_NM">
          </v-text-field>
        </div>
        <div class="ml-2 sm">
          <v-text-field
            class="form-inp sm"
            name="name"
            label="대상인원"
            hide-details
            outlined hint="대상인원"
            disabled
            persistent-hint
            v-model="detail.LM_DATA_COUNT">
          </v-text-field>
        </div>
        <div class="ml-2 sm">
          <v-text-field
            class="form-inp sm"
            name="name"
            label="응시인원"
            hide-details
            outlined hint="응시인원"
            disabled
            persistent-hint
            v-model="detail.LM_DONE_CNT">
          </v-text-field>
        </div>
        <div class="ml-2 sm">
          <v-text-field
            class="form-inp sm"
            name="name"
            label="응시율"
            hide-details
            outlined hint="응시율"
            :value="getParsent(detail.LM_DATA_COUNT, detail.LM_DONE_CNT)"
            disabled
            persistent-hint>
          </v-text-field>
        </div>
        <div class="ml-2 sm">
          <v-text-field
            class="form-inp sm"
            name="name"
            label="미응시인원"
            hide-details
            outlined hint="미응시인원"
            v-model="detail.LM_NON_DONE_CNT"
            disabled
            persistent-hint>
          </v-text-field>
        </div>
        <div class="ml-2 sm">
          <v-text-field
            class="form-inp sm"
            name="name"
            label="미응시율"
            hide-details
            outlined hint="미응시율"
            :value="getParsent(detail.LM_DATA_COUNT, detail.LM_NON_DONE_CNT)"
            disabled
            persistent-hint>
          </v-text-field>
        </div>
        <div class="ml-2 sm">
          <v-text-field
            class="form-inp sm"
            name="name"
            label="평균"
            hide-details
            outlined hint="평균"
            v-model="detail.AVG_RST_SUM"
            disabled
            persistent-hint>
          </v-text-field>
        </div>
        <div class="ml-2 sm">
          <v-text-field
            class="form-inp sm"
            name="name"
            label="목표점수 이상인원"
            hide-details
            outlined hint="목표점수 이상인원"
            v-model="detail.TG_SC_CNT"
            disabled
            persistent-hint>
          </v-text-field>
        </div>
      </div>
    </div>

    <div class="box-wrap">
      <div class="box-ct">
        <v-data-table
          dense
          :headers="gridDataHeaders2"
          :items="gridDataText2"
          :items-per-page="30"
          item-key="LM_DATA_ID"
          :page.sync="page"
          hide-default-footer
          class="grid-default case03"
          single-select
          calculate-widths
          fixed-header
          height="250px"
          @page-count="pageCount = $event"
          no-data-text="검색 결과가 없습니다."
          v-model="selGroupCd"
          @click:row="getListSel"
        >
          <template v-slot:item.LM_DIFF = {item}>
            {{(Number(item.LM_DATA_RST_SUM) - Number(detail.AVG_RST_SUM)).toFixed(1)}}
          </template>
        </v-data-table>
      </div>
    </div>

  </div>
</template>

<script>
import { mixin } from "@/mixin/mixin.js";
export default {
  name: "MENU_M710402", //name은 'MENU_' + 파일명 조합
  components: {},
  mixins: [mixin],
  data() {
    return {
      selGroupCd : [],
      grid01Search : {
        LM_TY : "",
        ASP_NEWCUST_KEY : "",
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
        START_DATE : '',
        END_DATE : '',
        LM_ID_NM : "",
      },
      menu1: false,
      gridDataHeaders: [
        {
          text: 'NO',
          sortable: true,
          value: 'ROW_NUMBER',
          align: "center",
        },
        { text: "회사구분", value: "ASP_CUST_NM", align: "center", sortable: true },
        { text: "유형타입", value: "LM_TY_NM", align: "center", sortable: true },
        { text: "시험명", value: "LM_ID_NM", align: "left", sortable: true },
        { text: "진행상태", value: "LM_PG_ST_NM", align: "center", sortable: true },
        { text: "시험시작일", value: "LM_ST_DTTM", align: "center", sortable: true },
        { text: "시험종료일", value: "LM_EN_DTTM", align: "center", sortable: true },
        { text: "제한시간", value: "LM_LIM_TIME", align: "center", sortable: true },
        { text: "문제수", value: "LM_EVA_RST_COUNT", align: "center", sortable: true },
        { text: "인원", value: "LM_DATA_COUNT", align: "center", sortable: true },
        { text: "제출자", value: "LM_DONE_CNT", align: "center", sortable: true },
        { text: "미제출자", value: "LM_NON_DONE_CNT", align: "center", sortable: true },
        { text: "총점", value: "LM_EVA_SUM", align: "center", sortable: true },
      ],
      gridDataText: [],
      grid01Selected : {},
      page: 1,
      pageCount: 0,
      itemsPerPage: 5,
      totalVisible: 10,

      gridDataHeaders2: [
        {
          text: '회사구분',
          sortable: true,
          value: 'ASP_CUST_NM',
          align: "center",
        },
        { text: '유형타입', align: "center", value: 'LM_TY_NM' ,sortable: true },
        { text: '사번', align: "left", value: 'LM_DATA_US_ID' ,sortable: true },
        { text: '상담사', align: "left", value: 'LM_DATA_US_NM' ,sortable: true },
        { text: '권한', align: "center", value: 'ATRT_GROUP_NM' ,sortable: true },
        { text: '입사일', align: "center", value: 'ENTCO_DT' ,sortable: true },
        { text: '점수', align: "center",value: 'LM_DATA_RST_SUM',sortable: true  },
        { text: '순위', align: "center", value: 'LM_RANK' ,sortable: true },
        { text: '평균차', align: "center", value: 'LM_DIFF' ,sortable: true },
      ],
      gridDataText2: [],
      groupCdList : ['PLT018'],
      allCodeList : [],
      detail : {
        LM_ID_NM : "",
        LM_TG_SC : 0,
        LM_DATA_COUNT : 0,
        LM_NON_DONE_CNT : 0,
        LM_DONE_CNT : 0,
        AVG_RST_SUM : 0,
        TG_SC_CNT : 0,
      },
    };
  },
  async mounted(){
    this.allCodeList = await this.mixin_common_code_get_all(this.groupCdList);
  },
  methods: {
    //Table row 클릭이벤트
    getListSel(item, row) {
      if(item != null){
        this.activeRow(row,true);
      }
    },

    activeRow(row, value){
      row.select(Boolean(value));
      row.isSelected = Boolean(value);
    },

    async selectRtnLm(){
      if(this.grid01Search.ASP_NEWCUST_KEY == undefined || this.grid01Search.ASP_NEWCUST_KEY == ''){
        this.common_alert("회사구분을 선택해주세요.","error");
      }else{
        this.selGroupCd = [];

        this.grid01Selected = {};
        this.detail = {};
        this.gridDataText2 = [];
        let requestData = {
          headers: {},
          sendData:{}
        };
        // header 세팅
        requestData.headers["URL"] = "api/statistics/phone/lm/list";
        requestData.headers["SERVICE"] = "statistics.phone.lm";
        requestData.headers["METHOD"] = "list";
        requestData.headers["TYPE"] = "BIZ_SERVICE";
        requestData.headers["ASYNC"] = false;

        // sendData 세팅
        requestData.sendData = this.grid01Search;

        // 결과 리턴
        let response = await this.common_postCall(requestData);

        if(!response.HEADER.ERROR_FLAG){
          this.gridDataText = response.DATA;
        }else{
          this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.","error");
        }

      }

    },

    async selectRtnLmDetail(){
      if(this.grid01Selected.LM_ID != undefined && this.grid01Selected.LM_ID != null && this.grid01Selected.LM_ID != ""){
        let requestData = {
          headers: {},
          sendData:{}
        };
        // header 세팅
        requestData.headers["URL"] = "api/statistics/phone/lm/detail/inqire";
        requestData.headers["SERVICE"] = "statistics.phone.lm.detail";
        requestData.headers["METHOD"] = "inqire";
        requestData.headers["TYPE"] = "BIZ_SERVICE";
        requestData.headers["ASYNC"] = false;

        // sendData 세팅
        requestData.sendData['LM_ID'] = this.grid01Selected.LM_ID;
        requestData.sendData['ASP_NEWCUST_KEY'] = this.grid01Selected.ASP_NEWCUST_KEY;


        // 결과 리턴
        let response = await this.common_postCall(requestData);

        if(!response.HEADER.ERROR_FLAG){
          this.detail = response.DETAIL[0];
          this.gridDataText2 = response.LIST;
        }
      }
    },
    isActiveRow(item){
      return item.LM_ID == this.grid01Selected.LM_ID? 'active':'';
    },
    setGrid01Select(item){
      this.grid01Selected = item;
      this.selectRtnLmDetail();
    },
    getParsent(total, count){
      let rtn = 0;
      if(total > 0){
        rtn = count / total * 100;
      }
      return rtn.toFixed(1);
    },
  },
  computed: {
    dateRangeText () {
      var newStartDate = this.grid01Search.START_DATE;
      var newEndDate = this.grid01Search.END_DATE;
      let returnVal = "";
      if(newStartDate !== '' && newEndDate !== ''){
        returnVal = newStartDate + " ~ " + newEndDate
      }
      return returnVal;
      // return this.dates.join(' ~ ')
    },
  },
};
</script>

<style>

</style>
